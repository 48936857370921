import bugsnag from '@bugsnag/js';

const bugsnagClient = bugsnag({
    apiKey: document.documentElement.getAttribute('data-bugsnag-api-key'),
    releaseStage: document.documentElement.getAttribute('data-bugsnag-release-stage'),
    collectUserIp: false,
    beforeSend: (report) => {
        if (report.app.releaseStage !== 'production') {
            const stacktrace = (report.stacktrace || [])
                .map(line => `    at ${line.method || ''} (${line.file}:${line.lineNumber}:${line.columnNumber})`)
                .join('\n');
            const errorMessage = `[Caught] ${report.errorClass}: ${report.errorMessage}\n${stacktrace}`;

            report.ignore();

            if (console && console.error) { // eslint-disable-line no-console
                console.error(errorMessage); // eslint-disable-line no-console
            } else {
                throw errorMessage;
            }
        }
    },
});

window.bugsnagClient = bugsnagClient;
